/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable operator-linebreak */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Button } from 'baseui/button';
import { useStyletron } from 'baseui';
import { useParams } from 'react-router-dom';
import TopNavigation from '../Containers/TopNavigation';
import JobHeader from '../Containers/JobHeader';
import JobDetail from '../Containers/JobDetail';
import Footer from '../Containers/Footer';
import AppContext from '../AppContext';
import LoadingScreen from '../Components/LoadingScreen/index';

const MAX_NUMBER_OF_RELECTED_JOBS = 5;
const FORM_URL = '//gfg.applytojob.com/apply/embed/form/{board_code}';

const mapPropsToState = (params, jobs) => {
  // id for backward compatible
  const keyName = 'board_code';
  const keyValue = params.boardCode;
  const currentJob = jobs.find((job) => job[keyName] === keyValue);

  if (currentJob) {
    // find related jobs by country and department first
    let relatedJobs = jobs.filter(
      ({ country_id: countryId, department, id: jobId }) => currentJob.country_id === countryId &&
        currentJob.department === department &&
        jobId !== currentJob.id,
    );
    if (relatedJobs.length < 1) {
      // find by country only
      relatedJobs = jobs.filter(
        ({ country_id: countryId, id: jobId }) => countryId === currentJob.country_id && jobId !== currentJob.id,
      );
    }

    if (relatedJobs.length < 1) {
      // find by department only
      relatedJobs = jobs.filter(
        ({ department, id: jobId }) => department === currentJob.department && jobId !== currentJob.id,
      );
    }

    relatedJobs = relatedJobs.splice(0, MAX_NUMBER_OF_RELECTED_JOBS);

    return {
      job: currentJob,
      relatedJobs,
    };
  }
  return {
    job: undefined,
    relatedJobs: undefined,
  };
};

const JobPage = () => {
  window.scrollTo(0, 0);
  const context = useContext(AppContext);
  const [css] = useStyletron();

  const { isLoading, jobs } = context;
  let job = {};
  let relatedJobs = [];
  const params = useParams();
  const state = mapPropsToState(params, jobs);
  job = state.job;
  relatedJobs = state.relatedJobs;

  const url = FORM_URL.replace('{board_code}', '');
  return (
    <>
      <LoadingScreen isLoading={isLoading} />
      <TopNavigation />
      {job ? (
        <>
          <JobHeader job={job} />
          <JobDetail job={job} relatedJobs={relatedJobs} />
        </>
      ) : (
        <div
          className={css({
            textAlign: 'center',
            margin: '50px 0',
          })}
        >
          <h1
            className={css({
              fontFamily: 'DINCond',
              fontSize: '24px',
              lineHeight: '29px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              marginTop: '0',
              '@media screen and (max-width: 599px)': {
                display: 'none',
              },
            })}
          >
            Currently we do not find any jobs match your search.
          </h1>
          <div>
            Please considered for any future opportunities, submit your CV below and we will be in
            touch when something suitable comes up.
          </div>
          <Button
            // eslint-disable-next-line no-return-assign
            onClick={() => (document.getElementById('jazzhrForm').style.display = 'inline-block')}
            overrides={{
              BaseButton: {
                style: {
                  backgroundColor: '#63BEDD',
                  fontFamily: 'DINCond',
                  fontSize: '15px',
                  lineHeight: '15px',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  letterSpacing: '0.95px',
                  padding: '25px 40px',
                  margin: '30px 0 0 0',
                },
              },
            }}
          >
            DROP YOUR RESUME
          </Button>
          <div>
            <iframe
              hidden
              id="jazzhrForm"
              className={css({
                border: 'none',
                margin: '40px 0 10px 0',
                width: '65%',
                height: '500px',
              })}
              title="jazzhrForm"
              src={url}
            />
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default JobPage;
