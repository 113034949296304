/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const FORM_URL = '//gfg.applytojob.com/apply/embed/form/{board_code}';

export default function JobDetail(props) {
  const [css] = useStyletron();

  const formURL = FORM_URL.replace('{board_code}', props.job.board_code);
  const headerH1 = css({
    fontFamily: 'DINCond',
    fontSize: '24px',
    lineHeight: '28px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    marginTop: '0',
    textTransform: 'uppercase',
    '@media screen and (max-width: 599px)': {
      marginTop: '20px',
    },
  });
  const rightCol = css({
    padding: '20px 0 0 40px ',
    borderLeft: '1px solid #DEDEDE',
    '@media screen and (max-width: 599px)': {
      border: 'none',
      padding: '0',
      marginBottom: '20px',
    },
  });

  // // document.title = props.job.title;
  // const meta = document.getElementsByTagName('meta');
  // for (let i = 0; i < meta.length; i++) {
  //   if (meta[i].name.toLowerCase() == 'description') {
  //     meta[i].content = props.job.description;
  //   }
  // }

  useEffect(() => {
    setTimeout(() => {
      if (window.addthis) window.addthis.layers.refresh();
    }, 700);
  }, []);

  const jd = props.job.description.replace(/<[^>]*>?/gm, '').replace('&nbsp;', ' ');

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{props.job.title}</title>
        <link rel="canonical" href="https://jobs.global-fashion-group.com/" />
        <meta name="description" property="og:description" content={jd} />
        <meta name="title" property="og:title" content={props.job.title} />
        <meta
          name="image"
          property="og:image"
          itemProp="image"
          content={`${window.location.origin}/static/media/LOGO-BLACK.d0d5515f.svg`}
        />
        <meta name="type" property="og:type" content="website" />
        <meta name="url" property="og:url" content={window.location.href} />
        <meta name="author" content="Global Fashion Group" />
      </Helmet>
      <Grid>
        <Cell span={[0, 8, 12]}>
          <div
            className={css({
              padding: '40px 0',
              textAlign: 'right',
            })}
          >
            <div className="addthis_inline_share_toolbox" />
          </div>
        </Cell>
      </Grid>
      <Grid>
        <Cell span={[4, 5, 9]}>
          <div dangerouslySetInnerHTML={{ __html: props.job.description }} />
          <div
            className={css({
              padding: '60px 0 0 0',
              '@media screen and (max-width: 599px)': {
                border: 'none',
                padding: '20px 0',
              },
            })}
          >
            <h1 className={headerH1}>SUBMIT A JOB APPLICATION</h1>
            <span>
              I acknowledge that I have read and understood the Data Privacy Policy, and I agree
              that the Data Privacy Policy shall apply to GFG’s collection, use, processing,
              retention and disclosure of my personal data for the purpose of my recruitment and
              consideration for employment by GFG. I understand that I can withdraw my consent at
              any time.
            </span>
          </div>
          <div
            className={css({
              padding: '0 0 40px 0',
              '@media screen and (max-width: 599px)': {
                padding: '0 0 10px 0',
              },
            })}
          >
            <Button
              id="submitCV"
              // eslint-disable-next-line no-return-assign
              onClick={() => (document.getElementById('jazzhrForm').style.display = 'inline-block')}
              overrides={{
                BaseButton: {
                  style: {
                    backgroundColor: '#63BEDD',
                    fontFamily: 'DINCond',
                    fontSize: '15px',
                    lineHeight: '15px',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    letterSpacing: '0.95px',
                    padding: '25px 100px',
                    margin: '30px 0 0 0',
                    '@media screen and (max-width: 599px)': {
                      margin: '10px 0 !important',
                      width: '100%',
                    },
                  },
                },
              }}
            >
              I AGREE
            </Button>
            <div>
              <iframe
                hidden
                id="jazzhrForm"
                className={css({
                  border: 'none',
                  margin: '40px 0 10px 0',
                  width: '65%',
                  height: '1000px',
                  '@media screen and (max-width: 599px)': {
                    width: '100%',
                  },
                })}
                title="jazzhrForm"
                src={formURL}
              />
            </div>
          </div>
        </Cell>
        <Cell span={[4, 0, 0]}>
          <div
            className={css({
              padding: '5px 0',
              textAlign: 'left',
            })}
          >
            <div className="addthis_inline_share_toolbox" />
          </div>
        </Cell>
        <Cell span={[4, 3, 3]}>
          <div className={rightCol}>
            <Button
              $as="a"
              onClick={() => {
                document.getElementById('submitCV').scrollIntoView({ behavior: 'smooth' });
              }}
              // eslint-disable-next-line no-return-assign
              overrides={{
                BaseButton: {
                  style: {
                    fontFamily: 'DINCond',
                    fontSize: '15px',
                    lineHeight: '15px',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    letterSpacing: '0.95px',
                    padding: '25px 0',
                    margin: '30px 0',
                    border: '1px solid #000000',
                    width: '100%',
                    '@media screen and (max-width: 599px)': {
                      display: 'none',
                    },
                  },
                },
              }}
            >
              APPLY NOW
            </Button>
            <h1 className={headerH1}>SIMILAR JOBS</h1>
            {props.relatedJobs.map((data) => (
              <div
                className={css({
                  padding: '1rem 0 0.5rem 1rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: '#fff',
                  position: 'relative',
                  border: 'none',
                  color: '#6d6d6d',
                  '@media screen and (max-width: 599px)': {
                    padding: '0.2rem 0 0.5rem 0rem',
                  },
                })}
              >
                <Link
                  to={`/job/${encodeURIComponent(data.title)}/${data.board_code}`}
                  className={css({ textDecoration: 'none', color: '#6d6d6d' })}
                >
                  {data.title}
                </Link>
              </div>
            ))}
          </div>
        </Cell>
      </Grid>
      <Grid>
        <Cell span={[4, 8, 12]} />
      </Grid>
    </div>
  );
}
