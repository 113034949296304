/* eslint-disable max-len */
import React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import headerImg from '../Assets/Images/header-image.png';
import mobileHeaderImg from '../Assets/Images/header-mobile.png';

export default function Header() {
  const [css] = useStyletron();
  const headerH1 = css({
    fontFamily: 'DINCond',
    fontSize: '24px',
    lineHeight: '29px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    marginTop: '0',
  });
  const headerText = css({
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
    fontSize: '16px',
    lineHeight: '20px',
    marginTop: '0',
    color: '#6D6D6D',
    textAlign: 'justify !important',
  });
  const headerLink = css({
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
    fontSize: '16px',
    lineHeight: '19px',
    textDecoration: 'none',
    color: '#1771B5',
  });
  return (
    <>
      <style>
        {`
          @media (max-width: 599px) {
            .about-us{
              margin-top: -23px;
            }
            .about-us h1{
              color: white;
            }
          }
        `}
      </style>
      <Grid gridMargins={[0, 16, 32]} gridGutters={0}>
        <Cell
          span={[4, 0, 0]}
        >
          <div className={css({
            overflow: 'hidden',
            width: '100%',
            maxHeight: '200px',
          })}
          >
            <img
              src={mobileHeaderImg}
              alt=""
              className={css({
                width: '100%',
              })}
            />
          </div>
        </Cell>
      </Grid>
      <Grid>
        <Cell span={[4, 3, 5]}>
          <div className="about-us">
            <h1 className={headerH1}>ABOUT US</h1>
            <span className={headerText}>
              Global Fashion Group (GFG) is the leading fashion and lifestyle destination in growth markets across LATAM, SEA and ANZ. From our people to our customers and partners, we exist to empower everyone to express their true selves through fashion. Our three e-commerce platforms: Dafiti, ZALORA and THE ICONIC connect an assortment of international, local and own brands to over 800 million consumers from diverse cultures and lifestyles. GFG’s platforms provide seamless and inspiring customer experiences from discovery to delivery, powered by art & science that is infused with unparalleled local knowledge. As part of the Group’s vision is to be the #1 online destination for fashion & lifestyle in growth markets, we are committed to doing this responsibly by being people and planet positive across everything we do.
            </span>
            <p className={css({ textAlign: 'right' })}>
              <a className={headerLink} href="https://global-fashion-group.com">
                View Our Website
                {' >'}
              </a>
            </p>
          </div>
        </Cell>
        <Cell span={[0, 5, 7]}>
          <img
            src={headerImg}
            alt=""
          />
        </Cell>
      </Grid>
    </>
  );
}
