/* eslint-disable no-restricted-properties */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/prop-types */
/* eslint-disable no-bitwise */
import React from 'react';
import { useStyletron } from 'baseui';
import { Grid, Cell } from 'baseui/layout-grid';
import { Button } from 'baseui/button';
import { Spinner } from 'baseui/spinner';
import Accordion from '../Components/Accordion/Accordion';

const FORM_URL = '//gfg.applytojob.com/apply/embed/form/{board_code}';

const filterGroups = (groups, jobFilter) => {
  const keyword = jobFilter.search.toLowerCase();
  const location = jobFilter.location.toLowerCase();
  const type = jobFilter.type.toLowerCase();

  if (!keyword && !location && !type) {
    return groups;
  }

  return groups
    .map((group) => {
      let { jobs } = group;

      if (location) {
        jobs = jobs.filter((job) => job.country_id.toLowerCase().indexOf(location) !== -1);
      }

      if (type) {
        jobs = jobs.filter((job) => job.type.toLowerCase().indexOf(type) !== -1);
      }

      if (keyword) {
        const words = keyword.split(' ').map((word, idx) => (word !== '' ? {
          word,
          score: Math.pow(10, -(idx + 1)),
        } : {}));

        // const sortedWords = words.sort((a, b) => b.length - a.length);

        jobs = jobs
          .map((job) => ({
            job,
            ...words.reduce(
              ({ found, score }, word) => {
                const idx = job.title.toLowerCase().indexOf(word.word);
                const wordFound = idx >= 0;

                const wordScore = wordFound ? word.score / (idx + 1) : 0;

                return {
                  found: found || wordFound,
                  score: score + wordScore,
                };
              },
              { found: false, score: 1 },
            ),
          }))
          .filter((job) => job.found)
          .sort((a, b) => b.score - a.score)
          .map(({ job, score }) => ({
            ...job,
            title: job.title,
            originalTitle: job.title,
            score,
          }));
      }

      return {
        score: jobs.length > 0 ? jobs[0].score || 0 : -1,
        group: {
          ...group,
          jobs,
        },
      };
    })
    .filter((group) => group.score > -1)
    .sort((a, b) => b.score - a.score)
    .map(({ group }) => group);
};

export default function JobList(props) {
  const [css] = useStyletron();

  const joblistH1 = css({
    fontFamily: 'DINCond',
    fontSize: '24px',
    lineHeight: '29px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    marginTop: '0',
    '@media screen and (max-width: 599px)': {
      display: 'none',
    },
  });

  // eslint-disable-next-line react/prop-types
  const { jobList, jobFilter, isLoading } = props;

  // eslint-disable-next-line react/prop-types
  // const filterJobs = jobList.filter((group) =>
  //   // eslint-disable-next-line react/prop-types
  //   group.jobs.some(
  //     ({ title, country_id: countryId, type }) =>
  //       (title.toLowerCase().indexOf(jobFilter.search.toLowerCase()) !== -1) &
  //       (countryId.toLowerCase().indexOf(jobFilter.location.toLowerCase()) !== -1) &
  //       (type.toLowerCase().indexOf(jobFilter.type.toLowerCase()) !== -1),
  //   ),
  // );

  const filterJobs = filterGroups(jobList, jobFilter);

  let jobContent;
  // eslint-disable-next-line react/prop-types
  if (isLoading) {
    jobContent = (
      <div
        className={css({
          textAlign: 'center',
          margin: '20px 0 50px 0',
        })}
      >
        <Spinner color="#000" />
      </div>
    );
  } else if (filterJobs.length !== 0) {
    jobContent = (
      <>
        <div className={css({ display: 'inline-block' })}>
          <h1 className={joblistH1}>CURRENT OPENINGS</h1>
        </div>
        <div
          className={css({
            padding: '0 0 10px 20px',
            display: 'inline-block',
            verticalAlign: 'middle',
            color: '#6D6D6D',
            '@media screen and (max-width: 599px)': {
              padding: '0 0 10px 0px',
            },
          })}
        >
          {`Showing ${filterJobs.reduce(
            (count, group) => (count += group.jobs.length),
            0,
          )} available positions`}
        </div>
        <Accordion jobList={filterJobs} />
      </>
    );
  } else {
    const url = FORM_URL.replace('{board_code}', 'r7GzcBsLHL');
    jobContent = (
      <div
        className={css({
          textAlign: 'center',
          margin: '20px 0 50px 0',
        })}
      >
        <h1 className={joblistH1}>Currently we do not have any new opportunities available</h1>
        <div>
          To be considered for any future opportunities, submit your CV below and we will be in
          touch when something suitable comes up.
        </div>
        <Button
          // eslint-disable-next-line no-return-assign
          onClick={() => (document.getElementById('jazzhrForm').style.display = 'inline-block')}
          overrides={{
            BaseButton: {
              style: {
                backgroundColor: '#63BEDD',
                fontFamily: 'DINCond',
                fontSize: '15px',
                lineHeight: '15px',
                fontStyle: 'normal',
                fontWeight: 'bold',
                letterSpacing: '0.95px',
                padding: '25px 40px',
                margin: '30px 0 0 0',
              },
            },
          }}
        >
          DROP YOUR RESUME
        </Button>
        <div>
          <iframe
            hidden
            id="jazzhrForm"
            className={css({
              border: 'none',
              margin: '40px 0 10px 0',
              width: '65%',
              height: '900px',
            })}
            title="jazzhrForm"
            src={url}
          />
        </div>
      </div>
    );
  }

  return (
    <Grid>
      <Cell span={[4, 8, 12]}>{jobContent}</Cell>
    </Grid>
  );
}
