import React from 'react';
import { useStyletron } from 'baseui';
import { Grid, Cell } from 'baseui/layout-grid';
import logo from '../Assets/Images/LOGO-WHITE.svg';
import linkedin from '../Assets/Images/linkedin.svg';
import twitter from '../Assets/Images/twitter.svg';

export default function Footer() {
  const [css] = useStyletron();
  const footer = css({
    fontFamily: 'DINCond',
    fontSize: '24px',
    lineHeight: '29px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    marginTop: '0',
    background: 'black',
    color: 'white',
    padding: '95px 0px 30px 0px',
    '@media screen and (max-width: 599px)': {
      padding: '50px 0px 10px 0px',
    },

  });
  const footerH1 = css({
    fontFamily: 'DINCond',
    fontSize: '24px',
    lineHeight: '29px',
    fontStyle: 'normal',
    fontWeight: '400',
    marginTop: '0',
    width: '100%',
    borderBottom: '1px solid #fff',
    textAlign: 'left',
    margin: '10px 0 20px',
    '@media screen and (max-width: 599px)': {
      margin: '35px 0 15px 0',
    },

  });

  const footerLink = css({
    width: '100% !important',
    fontFamily: 'DINCond',
    fontSize: '13px',
    lineHeight: '16px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    marginTop: '25px',
    '@media screen and (max-width: 599px)': {
      marginTop: '15px',
    },
  });

  const link = css({
    color: 'white',
    textDecoration: 'none',
  });

  const socialIcon = css({
    margin: '0 10px',
  });

  return (
    <div className={footer}>
      <Grid>
        <Cell span={[4, 2, 3]}>
          <a href="https://global-fashion-group.com">
            <img src={logo} alt="" />
          </a>
        </Cell>
        <Cell span={[4, 3, 5]}>
          <h1 className={footerH1}>
            <span className={css({ background: 'black', padding: '0 10px 0 0' })}>MARKETS</span>
          </h1>
          <table className={footerLink}>
            <tr>
              <td>
                <a className={link} href="https://global-fashion-group.com/markets/dafiti/">
                  DAFITI
                </a>
              </td>
              <td>
                <a className={link} href="https://global-fashion-group.com/markets/zalora/">
                  ZALORA
                </a>
              </td>
              <td>
                <a className={link} href="https://global-fashion-group.com/markets/the-iconic/">
                  THE ICONIC
                </a>
              </td>
            </tr>
          </table>
        </Cell>
        <Cell span={[4, 3, 4]}>
          <h1 className={footerH1}>
            <span className={css({ background: 'black', padding: '0 10px 0 0' })}>
              CONNECT WITH US
            </span>
          </h1>
          <a className={socialIcon} href="https://twitter.com/GFG">
            <img src={twitter} alt="" />
          </a>
          <a className={socialIcon} href="https://www.linkedin.com/company/global-fashion-group/">
            <img src={linkedin} alt="" />
          </a>
        </Cell>
      </Grid>
      <Grid>
        <Cell span={[4, 8, 12]}>
          <div
            className={css({
              padding: '80px 0 25px 60px',
              fontFamily: 'Lucida Grande',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '13px',
              lineHeight: '21px',
              '@media screen and (max-width: 599px)': {
                padding: '60px 0 25px 0px',
              },
            })}
          >
            <span>© Global Fashion Group 2020</span>
            {' | '}
            <a className={link} href="https://global-fashion-group.com/privacy-cookies-policy/">
              Privacy and Cookies Policies
            </a>
            {' | '}
            <a className={link} href="https://global-fashion-group.com/terms-of-use/">
              Terms of Use
            </a>
          </div>
        </Cell>
      </Grid>
    </div>
  );
}
